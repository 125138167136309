<template>
  <div id="status">
    <v-container grid-list-xl fluid>
      <header-title title="Estados"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="states"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <!-- <template v-slot:item.status="{ item }">
          <td>
            <span v-if="item.status == 1 || item.status == true || item.status == 'true'">
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>-->
        <template v-slot:item.actions="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editStatus(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmDelete(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="mdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Estado</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Estado"
                    :items="state_types"
                    v-model="state.tipo_estado_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="text"
                    label="Descripción *"
                    v-model="state.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createStatus()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateStatus()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="mdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="mconfirm" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteStatus()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mconfirm = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "status",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Tipo de estado ", value: "tipo_estado.descripcion" },
        { text: "Descripción ", value: "descripcion" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      states: [],
      state_types: [],
      state: {
        id: "",
        tipo_estado_id: "",
        descripcion: "",
      },
      mdialog: false,
      mconfirm: false,
      mode: true,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
    };
  },
  created() {
    this.getStatusTypes();
  },
  mounted() {
    const user = Service.getUser();
    if (user && user.role == 1) {
      this.getStatus();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    getStatusTypes() {
      this.loading = true;
      axios
        .get(Service.getBase() + "parametro/tipo/estado", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.state_types = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    getStatus() {
      this.loading = true;
      axios
        .get(Service.getBase() + "estados", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.states = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog() {
      this.state = {
        id: "",
        tipo_estado_id: "",
        descripcion: "",
      };
      this.mode = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.mdialog = true;
    },
    createStatus() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        axios
          .post(Service.getBase() + "estado", this.state, Service.getHeader())
          .then((response) => {
            this.btn_loading = false;
            this.toast("success", response.data);
            this.mdialog = false;
            this.getStatus();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    editStatus(data) {
      this.state = data;
      this.mode = false;
      this.mdialog = true;
    },

    updateStatus() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        axios
          .put(
            Service.getBase() + "estado/" + this.state.id,
            this.state,
            Service.getHeader()
          )
          .then((response) => {
            this.btn_loading = false;
            this.toast("success", response.data);
            this.mdialog = false;
            this.getStatus();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo actualizar");
          });
      }
    },

    confirmDelete(id) {
      this.state.id = id;
      this.mconfirm = true;
    },

    deleteStatus() {
      this.btn_loading = true;
      axios
        .delete(
          Service.getBase() + "estado/" + this.state.id,
          Service.getHeader()
        )
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.toast("success", "Registro eliminado");
            this.mconfirm = false;
            this.getStatus();
          } else {
            this.toast("error", "No se elimino el registro");
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast("error", "Registro en uso o no existente");
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
